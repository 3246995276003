import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { UpdateStep } from '../hooks/useSteps'
import { AnswersType, FormattedAnswer } from '@liftfoils/models'
import { Media } from '@liftfoils/components'

export type AnswersProps = {
  answers: FormattedAnswer[]
  answerId: keyof AnswersType
  onClick: UpdateStep
  appearance?: 'images' | 'text'
  additionalDescription?: string
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  justifySelf: 'center',
  maxWidth: 800,
  width: '100%',
  padding: '$4',

  '@md': {
    padding: 0,
  },

  variants: {
    hasImages: {
      true: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexDirection: 'row',
        gap: '$8',
      },
    },
  },
})

const TextAnswer = styled('button', {
  height: 70,
  width: 328,
  maxWidth: 'calc(100vw - 12px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$4',
  background: '$gray250_01',
  borderRadius: '$r1',
  color: '$white',

  '&:hover': {
    outline: '2px solid $teal500',
    transition: 'outline 100ms ease',
  },
})

const Title = styled('span', {
  textTransform: 'uppercase',
  width: '100%',
})

const Description = styled('span', {
  color: '$white_06',
  lift_font: 'body06',
})

const AdditionalDescription = styled('p', {
  color: '$white_06',
  lift_font: 'body04',
  fontSize: 14,
  mb: '$6',
  padding: '$4',
  maxWidth: 328,
  margin: '0 auto 20px',

  '@md': {
    padding: 0,
  },
})

const AnswerWrap = styled('div', {
  display: 'grid',
  placeItems: 'center',
})

const ImageWrap = styled('div', {
  height: 168,
  borderRadius: '$r3',
  overflow: 'hidden',
  marginBottom: '$7',
})

const ImageAnswer = styled('button', {
  width: 240,
  height: 196,
  background: 'none',
  border: 'none',
  color: '$gray300',
  textAlign: 'left',

  '&:hover': {
    '& > div': {
      outline: '3px solid $teal500',
      transition: 'outline 200ms ease',
    },

    '& > span': {
      color: '$white',
      transition: 'color 200ms ease',
    },
  },
})

export const Answers: FC<AnswersProps> = ({
  answers,
  onClick,
  appearance = 'text',
  additionalDescription,
  answerId,
}) => {
  const hasImages = appearance === 'images'

  return (
    <Container hasImages={hasImages}>
      {additionalDescription && (
        <AdditionalDescription>{additionalDescription}</AdditionalDescription>
      )}
      {answers.map(({ value, media, title, description }) => {
        return (
          <AnswerWrap key={`${answerId}-${title}`}>
            {hasImages && media?.mediaPayload ? (
              <ImageAnswer onClick={() => onClick('Next', value)}>
                <ImageWrap>
                  <Media
                    {...media}
                    sizes="220px"
                    layout="fill"
                    objectFit="cover"
                  />
                </ImageWrap>
                <Title>{title}</Title>
              </ImageAnswer>
            ) : (
              <TextAnswer onClick={() => onClick('Next', value)}>
                <Title>{title}</Title>
                {description && <Description>{description}</Description>}
              </TextAnswer>
            )}
          </AnswerWrap>
        )
      })}
    </Container>
  )
}
