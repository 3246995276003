import Link from 'next/link'
import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@liftfoils/styles'
import { Media, ProductVariantPrice } from '@liftfoils/components'
import {
  FormattedShopifyImage,
  ShopifyMoneyFieldsFragment,
  ShopifyResolvedImage,
} from '@liftfoils/shopify-sdk'
import { GearSelectorProductResult } from '@liftfoils/models'

export interface ProductCardProps extends GearSelectorProductResult {
  setSelectedVariants?: Dispatch<SetStateAction<string[]>>
  selectedVariant?: {
    id: string
    title: string
    price: {
      amount: number
      currencyCode: string
    }
    image?: FormattedShopifyImage | null
    availableForSale: boolean
  }
}

const Wrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '130px 160px',
  gap: '$6',
  mb: '$4',
  px: '$6',
  maxWidth: '100vw',
  margin: '0 auto $4',

  '@md': {
    gridTemplateColumns: '160px 160px',
    px: '$0',
    gap: '$8',
  },

  variants: {
    modalView: {
      true: {
        width: '100%',
      },
    },
  },
})

const ImageContainer = styled('div', {
  width: 130,
  height: 130,
  borderRadius: '$r1',
  overflow: 'hidden',

  '@md': {
    width: 160,
    height: 160,
  },

  variants: {
    modalView: {
      true: {
        background: '$gray100_05',
      },
      false: {
        background: '$white',
      },
    },
  },
})

const DetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: 160,
})

const Title = styled('h3', {
  lift_font: 'caps04',
  fontSize: 14,
})

const ViewProductCta = styled('span', {
  display: 'block',
  py: '$2',
  textDecoration: 'underline',
  lift_font: 'body03',
  textTransform: 'unset',
  color: '$gray500',

  '@md': {
    mt: '$6',
  },

  variants: {
    modalView: {
      false: {
        color: '$white_06',
      },
    },
  },
})

const PriceWrap = styled('div', {
  lift_font: 'caps04',
  fontSize: 14,
  color: '$gray300',
})

const Select = styled('select', {
  mt: '$4',
  mb: '$2',
  py: '$2',
  px: '$4',
  lift_font: 'body03',
  background: '$gray500_06',
  border: 'none',
  color: '$white',
  borderRadius: '8px',

  '@md': {
    mt: '$6',
  },
})

const AdditionalLabel = styled('p', {
  lift_font: 'body03',
  color: '$red500',

  '@md': {
    mt: '$6',
  },
})

export const ProductCard: FC<ProductCardProps> = ({
  image,
  link,
  title,
  modalView,
  variants,
  setSelectedVariants,
  selectedVariant,
  gearSelectorType,
  additionalMessage,
}) => {
  const { t } = useTranslation('gearSelector')
  const { t: cartT } = useTranslation('cart')
  const isAvailableForSale = selectedVariant?.availableForSale
  const handleSelectVariant = (event: ChangeEvent<HTMLSelectElement>) => {
    if (setSelectedVariants) {
      setSelectedVariants((prevState) => {
        const newState: string[] = [...prevState]
        const oldVariant = newState.find((v) =>
          variants?.find((variant) => variant.id === v),
        )
        if (oldVariant) {
          newState.splice(newState.indexOf(oldVariant), 1)
        }
        newState.push(String(event.target.value))
        return newState
      })
    }
  }

  return (
    <Wrap modalView={!!modalView}>
      <ImageContainer modalView={!!modalView}>
        <Media
          mediaPayload={
            selectedVariant?.image || (image as ShopifyResolvedImage)
          }
          sizes="220px"
          layout="fill"
          objectFit="contain"
        />
      </ImageContainer>
      <DetailsContainer>
        <Title>{title}</Title>
        <PriceWrap>
          <ProductVariantPrice
            price={selectedVariant?.price as ShopifyMoneyFieldsFragment}
          />
        </PriceWrap>
        {variants && variants?.length > 1 && (
          <Select value={selectedVariant?.id} onChange={handleSelectVariant}>
            {variants?.map((variant) => (
              <option key={variant.id} value={variant.id}>
                {variant.title}
              </option>
            ))}
          </Select>
        )}
        {!isAvailableForSale && (
          <AdditionalLabel>{cartT('outOfStock')}</AdditionalLabel>
        )}
        {additionalMessage && isAvailableForSale && (
          <AdditionalLabel>{additionalMessage}</AdditionalLabel>
        )}
        {gearSelectorType === 'classicFoil' && (
          <Link href={link} target="_blank">
            <ViewProductCta modalView={!!modalView}>
              {t('viewProduct')}
            </ViewProductCta>
          </Link>
        )}
      </DetailsContainer>
    </Wrap>
  )
}
