import { Dispatch, FC, SetStateAction, useState } from 'react'
import { styled } from '@liftfoils/styles'
import { useTranslation } from 'react-i18next'
import {
  AnswersType,
  FormattedClassicFoilGearSelectorSteps,
} from '@liftfoils/models'
import { Answers } from '../Answers/Answers'
import { Button } from '@liftfoils/components'
import { useSteps } from '../../hooks/useSteps'
import type { ProductCardProps } from '../../ProductCard/ProductCard'
import { ResultsContainer } from '../../ResultsContainer/ResultsContainer'

type ModalContentProps = {
  steps: FormattedClassicFoilGearSelectorSteps
  userAnswers: AnswersType
  setUserAnswers: Dispatch<SetStateAction<AnswersType>>
  gearSelectorType: 'classicFoil' | 'efoil'
}

const ModalContainer = styled('div', {
  display: 'grid',
  gridTemplateRows: '100px 1fr auto',
  width: 328,
  height: '100%',
  margin: '0 auto',
  paddingBottom: 40,
})

const StepTitle = styled('h2', {
  lift_font: 'body02',
  color: '$gray500',
  display: 'grid',
  placeItems: 'center',
})

const NextButton = styled(Button, {
  padding: '$6 $8',
  width: '100%',
  height: 55,
})

export const ModalContent: FC<ModalContentProps> = ({
  steps,
  setUserAnswers,
  userAnswers,
  gearSelectorType,
}) => {
  const { t } = useTranslation('gearSelector')
  const [results, setResults] = useState<ProductCardProps[] | null>(null)
  const {
    updateStep,
    currentStepData,
    isLastStep,
    isSecondToLastStep,
    setCurrentStep,
  } = useSteps({ steps, setUserAnswers })
  const answersStep = 'answers' in currentStepData
  const configStep = 'buyButtonDescription' in currentStepData

  const restart = () => {
    setCurrentStep(0)
    setUserAnswers({})
    setResults([])
  }

  return (
    <ModalContainer>
      <StepTitle>
        {answersStep
          ? `${t('selectYour')} ${currentStepData.answerId}:`
          : t('yourConfiguration')}
      </StepTitle>
      {answersStep && (
        <Answers
          answers={currentStepData.answers}
          answerId={currentStepData.answerId}
          userAnswers={userAnswers}
          setUserAnswers={setUserAnswers}
        />
      )}
      {isLastStep && configStep && (
        <ResultsContainer
          userAnswers={userAnswers}
          results={results}
          setResults={setResults}
          restart={restart}
          modalView
          gearSelectorType={gearSelectorType}
          outOfStockDisclaimer={currentStepData.outOfStockDisclaimer}
          disclaimer={currentStepData.disclaimer}
        />
      )}
      {!isLastStep && (
        <NextButton
          appearance="solidTeal500"
          onClick={() => updateStep('Next')}
          disabled={answersStep && !userAnswers[currentStepData.answerId]}
        >
          {isSecondToLastStep ? t('viewMyConfiguration') : t('next')}
        </NextButton>
      )}
    </ModalContainer>
  )
}
