import { FC, useState } from 'react'
import { styled } from '@liftfoils/styles'
import { useTranslation } from 'react-i18next'
import {
  FormattedAnswerStep,
  FormattedClassicFoilGearSelectorSteps,
  SanityImageMediaPayload,
} from '@liftfoils/models'
import { Header } from '../Header/Header'
import { Answers } from '../Answers/Answers'
import { ResultsContainer } from '../ResultsContainer/ResultsContainer'
import { useSteps } from '../hooks/useSteps'
import { ProductCardProps } from '../ProductCard/ProductCard'

export type GearSelectorProps = {
  steps: FormattedClassicFoilGearSelectorSteps
  gearSelectorType: 'classicFoil' | 'efoil'
}

const Wrap = styled('div', {
  py: '$headerHeight',
  minHeight: '100vh',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  alignItems: 'start',
  color: '$white',
  pt: '120px',
  gap: '$8',

  '@lg': {
    $hasContainerMX: 'true',
    pt: '160px',
    gap: 0,
  },
})

export const GearSelector: FC<GearSelectorProps> = ({
  steps,
  gearSelectorType,
}) => {
  const { t } = useTranslation('gearSelector')
  const [answers, setAnswers] = useState<{ [key: string]: string }>({})
  const [results, setResults] = useState<ProductCardProps[] | null>(null)

  const {
    updateStep,
    currentStep,
    setCurrentStep,
    isLastStep,
    currentStepData,
  } = useSteps({ steps, setUserAnswers: setAnswers })

  const isImagesStep =
    'answerId' in currentStepData &&
    (currentStepData as FormattedAnswerStep).answers?.every(
      (answer) =>
        (answer.media?.mediaPayload as SanityImageMediaPayload).image.asset,
    )

  const createTitle = () => {
    if (
      gearSelectorType === 'classicFoil' &&
      answers['experience'] &&
      answers['sport'] &&
      answers['weight']
    ) {
      return `
        ${t('perfectFor')}
        ${t(answers['experience']).toLowerCase()},
        ${t(answers['sport']).toLowerCase()}
        ${t('rider')},
        ${t(answers['weight']).toLowerCase()}
      `
    }
    if (gearSelectorType === 'efoil') {
      // TODO: Add custom title
      return 'Your perfect eFoil configuration'
    }

    return ''
  }

  const restart = () => {
    setCurrentStep(0)
    setAnswers({})
    setResults([])
  }

  return (
    <Wrap>
      <Header
        title={currentStepData.title}
        onClick={updateStep}
        isLastStep={isLastStep}
        lastStepTitle={createTitle()}
        numberOfResults={Array.isArray(results) ? results.length : 0}
        showBackButton={currentStep > 0}
      />
      {'answers' in currentStepData && (
        <Answers
          {...currentStepData}
          onClick={updateStep}
          appearance={isImagesStep ? 'images' : 'text'}
        />
      )}
      {isLastStep && (
        <ResultsContainer
          {...currentStepData}
          results={results}
          setResults={setResults}
          userAnswers={answers}
          restart={restart}
          gearSelectorType={gearSelectorType}
        />
      )}
    </Wrap>
  )
}
