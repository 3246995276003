import { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Modal } from '@liftfoils/components'
import { Close } from '@liftfoils/icons'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { breakpoints, styled, useMediaQuery } from '@liftfoils/styles'
import {
  AnswersType,
  FormattedClassicFoilGearSelectorSteps,
} from '@liftfoils/models'
import {
  getGearSelectorContentByPath,
  getGearSelectorPathByType,
} from '@liftfoils/services/sanity-service'
import { mapGearSelector } from '@liftfoils/services/shopify-service'
import { useGearSelectorContext } from '@liftfoils/gear-selector-provider'
import { ModalContent } from '../ModalContent/ModalContent'

type GearSelectorModalProps = {
  gearSelectorType?: 'classicFoil' | 'efoil'
}

const Wrap = styled('div', {
  height: '100%',
  position: 'relative',
  minHeight: '80vh',

  '@md': {
    width: 413,
    minHeight: 700,
  },
})

const CloseButton = styled('button', {
  position: 'absolute',
  top: '$8',
  right: '$8',
  padding: '$4',
  background: 'none',

  '> svg': {
    width: 15,
    height: 15,
    fill: '$gray500_06',
  },
})

export const GearSelectorModal: FC<GearSelectorModalProps> = ({
  gearSelectorType = 'classicFoil',
}) => {
  const { locale } = useRouter()
  const matchesMd = useMediaQuery(breakpoints.md)
  const [userAnswers, setUserAnswers] = useState<AnswersType>({})
  const [steps, setSteps] = useState<FormattedClassicFoilGearSelectorSteps>()
  const { isOpen, closeModal } = useGearSelectorContext()

  useEffect(() => {
    const getData = async () => {
      const [, localeId] = getLocaleRegionIdFromPath(locale)
      const path = await getGearSelectorPathByType(gearSelectorType)

      if (typeof path !== 'string' || !path) {
        console.error('Did not fetch the Gear Selector for the modal')
        return
      }

      const gearSelectorData = await getGearSelectorContentByPath(
        path,
        localeId,
      )
      const steps = mapGearSelector(gearSelectorData)

      setSteps(steps)
    }

    getData()
  }, [gearSelectorType, locale])
  if (!gearSelectorType) return null
  if (!steps || steps.length === 0) return null
  return (
    <Modal
      isOpen={isOpen}
      variant="white-rounded"
      size={matchesMd ? 'intrinsic' : 'stretch-x'}
      onRequestClose={() => closeModal()}
    >
      <Wrap>
        <CloseButton onClick={() => closeModal()}>
          <Close />
        </CloseButton>
        <ModalContent
          steps={steps}
          userAnswers={userAnswers}
          setUserAnswers={setUserAnswers}
          gearSelectorType={gearSelectorType}
        />
      </Wrap>
    </Modal>
  )
}
