import { PortableTextBlocks } from '@liftfoils/models'
import { FC } from 'react'
import { styled } from '@stitches/react'
import { PortableText } from '@liftfoils/components'

interface DisclaimerProps {
  availability?: boolean
  disclaimer?: PortableTextBlocks
  outOfStockDisclaimer?: PortableTextBlocks
  modalView?: boolean
}

const DisclaimerContainer = styled('div', {
  textAlign: 'center',
  color: '$gray300',

  a: {
    textDecoration: 'underline',
  },

  variants: {
    modalView: {
      true: {
        marginTop: '$7',
      },
      false: {
        marginTop: 55,
      },
    },
  },
})

export const Disclaimer: FC<DisclaimerProps> = ({
  outOfStockDisclaimer,
  disclaimer,
  availability,
  modalView = false,
}) => {
  return (
    <DisclaimerContainer modalView={modalView}>
      {!availability && outOfStockDisclaimer && (
        <PortableText value={outOfStockDisclaimer} />
      )}
      {availability && disclaimer && <PortableText value={disclaimer} />}
    </DisclaimerContainer>
  )
}
