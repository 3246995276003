import { FC, useEffect, useState } from 'react'
import { Button, Link, Loader } from '@liftfoils/components'
import { ProductCard, ProductCardProps } from '../ProductCard/ProductCard'
import { ConversionArea } from '../ConversionArea/ConversionArea'
import { keyframes, styled } from '@liftfoils/styles'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { ShopifyMoneyFieldsFragment } from '@liftfoils/shopify-sdk'
import { useTranslation } from 'react-i18next'
import {
  AnswersType,
  GearSelectorProductResult,
  PortableTextBlocks,
} from '@liftfoils/models'
import { BuyButtonWithTotal } from '@liftfoils/cart'
import { Disclaimer } from '../ConversionArea/Disclaimer'

type ResultsProps = {
  userAnswers: AnswersType
  results: GearSelectorProductResult[] | null
  setResults: (results: ProductCardProps[]) => void
  restart: () => void
  modalView?: boolean
  buyButtonDescription?: string
  sendToEmailDescription?: string
  disclaimer?: PortableTextBlocks
  outOfStockDisclaimer?: PortableTextBlocks
  gearSelectorType: 'classicFoil' | 'efoil'
}
export type MainProduct = {
  path?: string
  title?: string
  kitsLength?: number
}
type GearSelectorApiResult = {
  data: {
    products: ProductCardProps[]
    mainProduct?: MainProduct
    selectedVariantIds: string[]
    totalPrice: ShopifyMoneyFieldsFragment
  }
}

const Wrap = styled('div', {
  height: '100%',
})

const LoaderContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
})

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

const ResultsWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  maxWidth: '980px',
  width: '100%',
  animation: `${fadeIn} 600ms ease`,
  gap: '$11',

  '@md': {
    gap: 0,
    flexDirection: 'row',
  },
})

const Column = styled('div', {
  width: '100%',
  minHeight: '160px',
  lift_font: 'body04',
  display: 'grid',

  '&:first-child': {
    border: 'none',
  },

  variants: {
    modalView: {
      true: {
        '&:first-child': {
          paddingRight: 'none',
        },

        select: {
          backgroundColor: '$white',
          color: '$black',
          border: '1px solid $gray500_06',
        },

        '@md': {
          borderRight: 'none',
          paddingRight: 'none',
        },
      },
      undefined: {
        '&:first-child': {
          paddingRight: 'none',

          '@md': {
            borderRight: '1px solid $gray500_08',
          },
        },
      },
    },
  },
})

const BuyButtonWrap = styled('div', {
  mt: '$7',
})

const NoResultsWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
  width: '100%',
  gap: '$8',

  button: {
    padding: '$6 $10',
  },
})

const FullConfigLink = styled(Link, {
  padding: '$4',
  width: '100%',
  height: 55,
  marginBottom: '$4',
})

const NoResults = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  lift_font: 'body02',
})

export const ResultsContainer: FC<ResultsProps> = ({
  userAnswers,
  setResults,
  results,
  restart,
  modalView,
  buyButtonDescription,
  sendToEmailDescription,
  disclaimer,
  outOfStockDisclaimer,
  gearSelectorType,
}) => {
  const { t } = useTranslation('gearSelector')
  const [loading, setLoading] = useState(true)
  const [totalPrice, setTotalPrice] = useState<{
    amount: number
    currencyCode: string
  }>({ amount: 0, currencyCode: 'USD' })
  const [selectedVariants, setSelectedVariants] = useState<string[]>([])
  const [selectedOptions, setSelectedOptions] = useState<
    { key: string; value: string }[]
  >([])
  const [mainProductData, setMainProductData] = useState<MainProduct>({})
  const [availability, setAvailability] = useState(true)
  const [, localeId] = getLocaleRegionIdFromPath()

  const getSelectedVariant = (product: GearSelectorProductResult) =>
    product.variants?.find((variant) => selectedVariants.includes(variant.id))
  const getSelectedOptions = (
    products: ProductCardProps[],
    selectedVariantIds: string[],
  ): { key: string; value: string }[] => {
    if (!products || !products.length) return []

    return products.flatMap((product) => {
      if (!product.variants || !product.variants.length) return []

      return product.variants
        .filter((variant) => selectedVariantIds.includes(variant.id))
        .flatMap((variant) => {
          return product.options
            .map((option) => {
              const optionValue = variant.title
                .split(' / ')
                .find((value) => option.values.includes(value))
              return optionValue
                ? { key: option.name, value: optionValue }
                : null
            })
            .filter(Boolean)
        })
    })
  }
  const createQueryString = () => {
    if (!mainProductData?.path) return

    const baseUrl = document.location.origin
    const url = new URL(`${baseUrl}${mainProductData.path}`)
    const params = new URLSearchParams()

    selectedOptions.forEach((option) => {
      if (!option) return
      params.append(option.key, option.value)
    })

    return url + '?' + params.toString()
  }
  const fullConfigLink =
    gearSelectorType === 'efoil' ? createQueryString() : undefined

  useEffect(() => {
    if (gearSelectorType === 'classicFoil') {
      setSelectedVariants(
        (results?.map((result) => result.variants?.[0]?.id) as string[]) || [],
      )
    }
  }, [results])

  useEffect(() => {
    if (gearSelectorType === 'efoil') {
      setSelectedOptions(
        getSelectedOptions(results as ProductCardProps[], selectedVariants),
      )
    }

    const variantsPrices = results
      ?.map((result) =>
        result.variants?.find((variant) => {
          return selectedVariants.includes(variant.id)
        }),
      )
      .filter((variant) => !!variant?.price)
      .map((variant) => ({
        amount: variant?.price.amount,
        currencyCode: variant?.price.currencyCode,
      }))

    const totalPrice = variantsPrices?.reduce(
      (acc, curr) => {
        acc.amount += Number(curr.amount)
        return acc
      },
      { amount: 0, currencyCode: 'USD' },
    )

    setTotalPrice(totalPrice as ShopifyMoneyFieldsFragment)
  }, [selectedVariants])

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true)
      try {
        const response = await fetch('/api/gear-selector', {
          method: 'POST',
          body: JSON.stringify({
            answers: userAnswers,
            gearSelectorType,
            locale: localeId,
          }),
        })

        const result: GearSelectorApiResult = await response.json()
        if (!result.data) {
          console.warn('No data returned from the API')
          setResults([])
        }
        const { totalPrice, selectedVariantIds, products, mainProduct } =
          result.data

        setResults(products)
        setTotalPrice(totalPrice)
        setSelectedVariants(selectedVariantIds)
        setMainProductData({ ...mainProduct })

        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchResults()

    return () => {
      setLoading(false)
      setResults([])
      setTotalPrice({ amount: 0, currencyCode: 'USD' })
    }
  }, [localeId, userAnswers])

  useEffect(() => {
    if (!results?.length) return
    const availability = results
      .map(getSelectedVariant)
      .every((v) => v?.availableForSale)
    setAvailability(availability)
  }, [results, selectedVariants])

  const displayAvailabilityDisclaimer =
    results?.some((result) => result.additionalMessage) && availability

  return (
    <Wrap>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <ResultsWrap>
          {Array.isArray(results) && results.length > 0 ? (
            <>
              <Column modalView={modalView}>
                {results.map((result) => (
                  <ProductCard
                    {...result}
                    key={result.title}
                    modalView={modalView}
                    setSelectedVariants={setSelectedVariants}
                    selectedVariant={getSelectedVariant(result)}
                    gearSelectorType={gearSelectorType}
                  />
                ))}
                {modalView && (
                  <BuyButtonWrap>
                    <FullConfigLink
                      appearance="solidTeal500"
                      href={fullConfigLink}
                      target="_blank"
                    >
                      Go to your configuration
                    </FullConfigLink>
                    <BuyButtonWithTotal
                      selectedVariants={selectedVariants}
                      totalPrice={totalPrice as ShopifyMoneyFieldsFragment}
                      mainProductData={mainProductData}
                      bundle={!!fullConfigLink}
                      attributes={selectedOptions}
                    />
                    <Disclaimer
                      disclaimer={
                        displayAvailabilityDisclaimer ? disclaimer : undefined
                      }
                      outOfStockDisclaimer={outOfStockDisclaimer}
                      availability={availability}
                      modalView={modalView}
                    />
                  </BuyButtonWrap>
                )}
              </Column>
              {!modalView ? (
                <Column>
                  <ConversionArea
                    totalPrice={totalPrice as ShopifyMoneyFieldsFragment}
                    buyButtonDescription={buyButtonDescription}
                    sendToEmailDescription={sendToEmailDescription}
                    selectedVariants={selectedVariants}
                    mainProductData={mainProductData}
                    fullConfigLink={fullConfigLink}
                    attributes={selectedOptions}
                    availability={availability}
                    disclaimer={
                      displayAvailabilityDisclaimer ? disclaimer : undefined
                    }
                    outOfStockDisclaimer={outOfStockDisclaimer}
                  />
                </Column>
              ) : null}
            </>
          ) : (
            <NoResultsWrap>
              <NoResults>{t('noProductsFound')}</NoResults>
              <Button appearance="blackBlur" onClick={restart}>
                {t('restart')}
              </Button>
            </NoResultsWrap>
          )}
        </ResultsWrap>
      )}
    </Wrap>
  )
}
