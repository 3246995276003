import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { ArrowLeft } from '@liftfoils/icons'
import { Button } from '@liftfoils/components'
import { UpdateStep } from '../hooks/useSteps'
import { useTranslation } from 'react-i18next'

type HeaderProps = {
  title: string
  onClick: UpdateStep
  showBackButton?: boolean
  numberOfResults?: number
  isLastStep: boolean
  lastStepTitle: string
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  minHeight: 120,
})

const GearSelectorTitle = styled('h1', {
  lift_font: 'caps05',
  marginTop: 70,
  textAlign: 'center',

  '@md': {
    marginTop: 0,
  },
})

const StepTitle = styled('h2', {
  lift_font: 'heading02',
  color: '$gray300',
  textAlign: 'center',
  px: '$4',
})

const BackButton = styled(Button, {
  position: 'absolute',
  left: '$8',
  top: 0,
  px: '$8',
  py: '$6',

  '@lg': {
    left: 0,
  },
})

export const Header: FC<HeaderProps> = ({
  title,
  onClick,
  showBackButton,
  isLastStep,
  lastStepTitle,
}) => {
  const { t } = useTranslation('gearSelector')
  return (
    <Container>
      {showBackButton && (
        <BackButton onClick={() => onClick('Prev')} appearance="solidGray500">
          <ArrowLeft />
        </BackButton>
      )}
      <GearSelectorTitle>
        {isLastStep ? (
          <span>{t('yourConfiguration')}</span>
        ) : (
          <span>{t('buildYourConfiguration')}</span>
        )}
      </GearSelectorTitle>
      <StepTitle>{isLastStep ? lastStepTitle : title}</StepTitle>
    </Container>
  )
}
