import {
  FormattedAnswerStep,
  FormattedClassicFoilGearSelectorSteps,
  FormattedConfigurationStep,
} from '@liftfoils/models'
import { Dispatch, SetStateAction, useState } from 'react'

export type Action = 'Prev' | 'Next'

export type UpdateStep = (action: Action, answerValue?: string) => void

type UseStepProps = {
  steps: FormattedClassicFoilGearSelectorSteps
  setUserAnswers: Dispatch<SetStateAction<{ [key: string]: string }>>
}
type UseSteps = {
  isLastStep: boolean
  isSecondToLastStep: boolean
  setCurrentStep: Dispatch<SetStateAction<number>>
  updateStep: UpdateStep
  currentStepData: FormattedAnswerStep | FormattedConfigurationStep
  currentStep: number
}

export const useSteps = ({ steps, setUserAnswers }: UseStepProps): UseSteps => {
  const [currentStep, setCurrentStep] = useState(0)
  const currentStepData = steps[currentStep]
  const isLastStep = currentStep === steps.length - 1
  const isSecondToLastStep = currentStep === steps.length - 2

  const updateStep: UpdateStep = (action, answerValue) => {
    const isPrevAction = action === 'Prev' && currentStep > 0
    const isNextAction = action === 'Next' && currentStep < steps.length - 1

    if (isPrevAction || isNextAction) {
      setCurrentStep((prevState) => prevState + (isPrevAction ? -1 : 1))
    }
    if (answerValue) {
      setUserAnswers((prevState) => ({
        ...prevState,
        [(currentStepData as FormattedAnswerStep).answerId]: answerValue,
      }))
    }
  }

  return {
    updateStep,
    setCurrentStep,
    isLastStep,
    isSecondToLastStep,
    currentStepData,
    currentStep,
  }
}
