import { FC } from 'react'
import { styled } from '@stitches/react'
import { ShopifyMoneyFieldsFragment } from '@liftfoils/shopify-sdk'
import { BuyButtonWithTotal } from '@liftfoils/cart'
import { Link } from '@liftfoils/components'
import { MainProduct } from '../ResultsContainer/ResultsContainer'
import { PortableTextBlocks } from '@liftfoils/models'
import { Disclaimer } from './Disclaimer'

type ConversionAreaProps = {
  totalPrice: ShopifyMoneyFieldsFragment
  buyButtonDescription?: string
  sendToEmailDescription?: string
  selectedVariants: string[]
  fullConfigLink?: string
  attributes?: { key: string; value: string }[]
  mainProductData?: MainProduct
  availability?: boolean
  disclaimer?: PortableTextBlocks
  outOfStockDisclaimer?: PortableTextBlocks
}

const Wrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$10',

  '@md': {
    gridGap: '$2',
    height: '70%',
    margin: 'auto',
  },
})

const Section = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  width: '100%',
  paddingInline: '$6',
  maxWidth: 335,
  margin: '0 auto',

  '@sm': {
    paddingInline: '$0',
  },

  input: {
    borderRadius: '999px',
    backgroundColor: '$gray500',
    borderColor: '$gray500',
  },

  label: {
    color: '$white',
  },
})

const CallToAction = styled('label', {
  lift_font: 'caps03',
  textTransform: 'uppercase',
  color: '$white',
  marginBottom: '$8',
})

const FullConfigLink = styled(Link, {
  padding: '$4',
  width: '100%',
  height: 55,
  marginBottom: '$8',
})

// TODO: Uncomment when implementing products Email submission
// const MiddleText = styled('span', {
//   color: '$white_06',
//   textTransform: 'uppercase',
// })
//
// const EmailForm = styled('form', {
//   position: 'relative',
//   width: '100%',
//   display: 'flex',
//   height: 55,
// })
//
// const SendButton = styled(Button, {
//   position: 'absolute',
//   right: '$8',
//   height: '100%',
//   padding: '$6',
// })
//
// const EmailInput = styled('input', {
//   height: '55px',
//   width: '100%',
//   border: 'none',
//   paddingLeft: '$8',
//   color: '$white',
//
//   '&:focus': {
//     outline: '1px solid $teal500',
//   },
//
//   '&::placeholder': {
//     color: '$white',
//   },
// })

export const ConversionArea: FC<ConversionAreaProps> = ({
  totalPrice,
  buyButtonDescription,
  selectedVariants,
  fullConfigLink,
  attributes,
  mainProductData,
  availability,
  disclaimer,
  outOfStockDisclaimer,
  // sendToEmailDescription,
}) => {
  // TODO: Uncomment when implementing products Email submission
  // const { t } = useTranslation('gearSelector')
  // const [emailValue, setEmailValue] = useState<string | undefined>('')

  // const handleSendToEmail = () => {
  //   console.log(emailValue)
  // }

  return (
    <Wrap>
      <Section>
        {fullConfigLink && (
          <>
            <CallToAction>View full configuration</CallToAction>
            <FullConfigLink
              appearance="solidTeal500"
              href={fullConfigLink}
              target="_blank"
            >
              Go to your configuration
            </FullConfigLink>
          </>
        )}
        <CallToAction>{buyButtonDescription || 'Buy the package'}</CallToAction>
        <BuyButtonWithTotal
          totalPrice={totalPrice}
          selectedVariants={selectedVariants}
          bundle={!!fullConfigLink}
          attributes={attributes}
          mainProductData={mainProductData}
          availability={availability}
        />
        <Disclaimer
          disclaimer={disclaimer}
          outOfStockDisclaimer={outOfStockDisclaimer}
          availability={availability}
        />
      </Section>
      {/*TODO: Uncomment when implementing products Email submission*/}
      {/*<MiddleText>{t('or')}</MiddleText>*/}
      {/*<Section>*/}
      {/*<CallToAction htmlFor="gear-selector-email">*/}
      {/*  {sendToEmailDescription || 'Send to Email'}*/}
      {/*</CallToAction>*/}
      {/*/!* TODO: Handle submit*!/*/}
      {/*<EmailForm*/}
      {/*  onSubmit={(e) => {*/}
      {/*    e.preventDefault()*/}
      {/*    handleSendToEmail()*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <EmailInput*/}
      {/*    id="gear-selector-email"*/}
      {/*    type="email"*/}
      {/*    onChange={(e) => setEmailValue(e.target.value)}*/}
      {/*    value={emailValue}*/}
      {/*    placeholder={t('enterEmailAddress') || 'Enter email address'}*/}
      {/*  />*/}
      {/*  <SendButton>Send</SendButton>*/}
      {/*</EmailForm>*/}
      {/*</Section>*/}
    </Wrap>
  )
}
