import { Dispatch, FC, SetStateAction } from 'react'
import { styled } from '@liftfoils/styles'
import { AnswersType } from '@liftfoils/models'
import { AnswersProps } from '../../Answers/Answers'

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  justifyContent: 'start',
  alignItems: 'center',
})

const ModalAnswer = styled('button', {
  height: 70,
  width: '100%',
  maxWidth: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$4',
  background: 'transparent',
  borderRadius: '$r1',
  color: '$gray500',
  border: '1px solid $gray500_02',

  variants: {
    selected: {
      true: {
        border: '2px solid $teal500',
        transition: 'border 100ms ease',
      },
    },
    largeTiles: {
      true: {
        height: 140,
      },
    },
  },
})

const Title = styled('span', {
  textTransform: 'uppercase',
  width: '100%',
})

const AnswersBlock = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  width: 328,
  maxWidth: '95vw',
})

export type ModalAnswersProps = Pick<AnswersProps, 'answers' | 'answerId'> & {
  userAnswers: AnswersType
  setUserAnswers: Dispatch<SetStateAction<AnswersType>>
}

export const Answers: FC<ModalAnswersProps> = ({
  answers,
  answerId,
  userAnswers,
  setUserAnswers,
}) => {
  return (
    <Container>
      <AnswersBlock>
        {answers.map(({ value, title }) => {
          return (
            <ModalAnswer
              key={`modal-${answerId}-${title}`}
              selected={userAnswers[answerId] === value}
              largeTiles={answers.length <= 3}
              onClick={() =>
                setUserAnswers((prevState) => ({
                  ...prevState,
                  [answerId]: value,
                }))
              }
            >
              <Title>{title}</Title>
            </ModalAnswer>
          )
        })}
      </AnswersBlock>
    </Container>
  )
}
