import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { Action } from '@liftfoils/models'
import { Link } from '@liftfoils/components'

type CallToActionLinkSectionProps = {
  title: string
  ctaTitle: string
  link: {
    action?: Action
    href?: string
  }
}

const Wrap = styled('div', {
  borderTop: '1px solid $gray500_01',
  borderBottom: '1px solid $gray500_01',
  py: '$8',
  $my: '$8',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lift_font: 'heading04',
  color: '$gray500_06',
})

const CtaLink = styled(Link, {
  padding: '$6 $8',
})

export const CallToActionLinkSection: FC<CallToActionLinkSectionProps> = ({
  title,
  ctaTitle,
  link,
}) => {
  return (
    <Wrap>
      <span>{title}</span>
      <CtaLink appearance="solidTeal500" {...link}>
        {ctaTitle}
      </CtaLink>
    </Wrap>
  )
}
